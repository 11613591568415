import { useIntl } from "react-intl";
import cx from "classnames";
import { Link, LinkType } from "@pl/app-component-lib";

export type FooterProps = {
  linkType?: (typeof LinkType)[number];
  color?: "inverse" | "default";
};

export function Footer({
  linkType = "inverse",
  color = "inverse",
}: FooterProps) {
  const { formatMessage } = useIntl();

  return (
    <div>
      <div className="mb-4 flex flex-wrap justify-center gap-x-6">
        <Link
          type={linkType}
          as="a"
          href="https://support.paymentlabs.io"
          target="_blank"
          text={formatMessage({
            id: "login.footer.support",
          })}
        />
        <Link
          type={linkType}
          as="a"
          href="https://www.paymentlabs.io/privacy-policy"
          target="_blank"
          text={formatMessage({
            id: "login.footer.privacy",
          })}
        />
        <Link
          type={linkType}
          as="a"
          href="https://www.paymentlabs.io/terms-of-service"
          target="_blank"
          text={formatMessage({
            id: "login.footer.legal",
          })}
        />
      </div>
      <p
        className={cx("text-body-xs text-center", {
          "text-txt-inverse": color === "inverse",
          "text-txt-default": color === "default",
        })}
      >
        © {new Date().getFullYear()} Payment Pro Logistics, LLC. All Rights
        Reserved.
      </p>
    </div>
  );
}
